import { FaqItem } from './shared/types'

export const commonFaq: FaqItem[] = [
  {
    question: 'Що таке ID картка?',
    id: 'id-card',
  },
  {
    question: 'Що таке MobileID?',
    id: 'mobile-id',
  },
  {
    question: 'Що таке хмарне сховище?',
    id: 'cloud',
  },
  {
    question: 'Що таке токен?',
    id: 'token',
  },
  {
    question: 'Що таке файловий носій?',
    id: 'file',
  },
  {
    question: 'Що таке електронний підпис?',
    id: 'sign',
  },
  {
    question: 'Що таке виїзна генерація?',
    id: 'outbound-generation',
  },
]

export const signFaq: FaqItem[] = [
  {
    question: 'Отримати послугу КЕП',
    id: 'get-service',
  },
  {
    question: 'Як анулювати Дія.Підпис?',
    id: 'decline',
  },
  {
    question: 'Як повернути кошти за неотриману послугу?',
    id: 'refaund',
  },
  {
    question: 'Перелік документів на реєстрацію',
    id: 'doc-list',
  },
  {
    question: 'Накладення КЕП',
    id: 'cover',
  },
  {
    question: 'Захищені носії',
    id: 'devices',
  },
  {
    question: 'Пароль особистого ключа (КЕП)',
    id: 'password',
  },
  {
    question: 'Порядок отримання КЕП',
    id: 'roadmap',
  },
  {
    question: 'Якими бувають електронні підписи?',
    id: 'sign-types',
  },
]

export const certificateFaq: FaqItem[] = [
  {
    question: 'Статус кваліфікованого сертифікату',
    id: 'status',
  },
  {
    question: 'Зміна статусу кваліфікованого сертифікату',
    id: 'replace',
  },
  {
    question: 'Кваліфікований сертифікат електронного підпису чи печатки',
    id: 'certificate',
  },
]
